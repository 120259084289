<template>
  <section>
    <div class="content-header">
      <h2>About power</h2>
    </div>
    <div class="content-wrapper">
      <p>Power can be described as “the ability to control circumstances.” </p>
      <p>Oftentimes, political scientists define power as the ability to influence the behaviour of others, with or without resistance. </p>
      <p>Within social justice work, power can be defined as access to resources and/or privileges. Depending on your social position, you are more than likely to have different amounts of power in various parts of your life.</p>
      <p>Traditionally, there are some groups that have more access to societal power and some that have less.</p>
      <div class="accordion">
              <accordion
                headerText="Groups with access to more power"
                accordionID="collapse01"
              >
                <ul>
                  <li>Men</li>
                  <li>White people</li>
                  <li>Upper/middle class and rich people</li>
                  <li>Adults</li>
                  <li>Heterosexuals</li>
                  <li>Able-bodied/able-minded people</li>
                  <li>Canadian-born people and Canadian citizens</li>
                </ul>
              </accordion>
              <accordion
                headerText="Groups with less access to power"
                accordionID="collapse02"
              >
                <ul>
                  <li>Women</li>
                  <li>Black, Indigenous and People of colour</li>
                  <li>Poor and working-class people</li>
                  <li>Youth and elderly people</li>
                  <li>Two Spirit, lesbian, gay, bisexual, transgender, queer, questioning, intersex and asexual (2SLGBTQQIA+) people</li>
                  <li>People with disabilities</li>
                  <li>Immigrants</li>
                </ul>
              </accordion>
            </div>
            <p class="lm">Learn more</p>
            <p>Every day, we move and operate within systems of power that other people have constructed. But we’re often uncomfortable talking about power. Why? </p>
            <p>In the Ted Talk, <a href="https://www.youtube.com/watch?v=c_Eutci7ack&t=109s" target="_blank">“How to understand power”</a>, writer, educator and civic entrepreneur Eric Liu describes the six sources of power and explains how understanding them is key to being an effective citizen. </p>
            <p>As you watch the video, reflect on three questions:</p>
            <ul>
              <li>What groups have had access to power within our society?</li>
              <li>What groups have had less power?</li>
              <li>What systems have allowed for this power imbalance to occur?</li>
            </ul>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
